* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --color-131921: #131921;
  --color-3b4149: #3b4149;
  --color-febd69: #FFE300;
  --color-232f3e: #232f3e;
  --color-bf4800: #FFD31D;
  --color-f5f5f7: #f5f5f7;
  --color-ededed: #ededed;
  --color-777777: #777777;
  --color-1c1c1b: #1c1c1b;

}


* {
  font-family: Roboto, sans-serif;  
}

a {
  text-decoration: none;
  display: inline-block;
}

.error {
  color: red !important;
  font-size: 12px !important;
}

.logo-header {
  width: 65px;
  height: 65px;
}

.green{
    color: green;
    font-size: 14px;
    font-family: Roboto !important;
}
.red{
    color: red;
    font-size: 14px;
    font-family: Roboto !important;
}

.card-wrapper {
  background-color: white;
  box-shadow: 0 0 10px #0000001a;
  padding: 15px;
}

.carousel-img {
  /* max-height: 500px !important; */
  object-fit: contain !important;
  width: 100% !important;
  background-color: #FFD31D;
}

.section-heading {
  font-size: 28px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 30px;
  color: #444444;

}

.form-control:focus {
  box-shadow: none;
  border-color: inherit;
}

.gap-5 {
  gap: 10px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-30 {
  gap: 30px;
}
.button {
  background-color: var(--color-232f3e);
  color: white;
  font-size: 14px;
  padding: 13px 33px;
  border-radius: 25px;
}
.button:hover {
  background-color: var(--color-febd69);
  color: #131921;
}

.button-other {
  background-color: transparent;
  color: white;
  font-size: 14px;
  padding: 13px 33px;
  border-radius: 25px;
  border: 1px solid #ffffff;
  
}
.button-other:hover {
  background: -webkit-linear-gradient(90deg,  var(--color-bf4800) , #1b1a17);
  color: #131921;
  border: 1px solid transparent;

}
/*////////////////////////////////////////////////////////////////*/
/*Header Start*/
.gradient-yellow-text {
  background: -webkit-linear-gradient(90deg, #FFFFFF, #ffce2e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 22px;
}

.black-text {
  color: #000000;
}

.black-text :hover {
  color: #FFE300;
}

.orange-text {
  color: #ffffff;
}
.orange-text :hover {
  color: #FFE300;
}
.header-top-strip {
  background: radial-gradient(circle at 10% 20%, #444444 0%,  #000000 90%);
}
.header-upper {
  background-color: white;
}
.header-upper .input-group-text {
  background-color: #444444;
}
.header-upper .header-upper-links img {
  width: 35px;
  height: 35px;
}
.header-upper .header-upper-links p {
  color: #444444;
}
.typeahead{
    background-color: #fff !important;
    min-width: 250px;
}

.typeahead li{
    background-color: #fff !important;
}

.header-upper .header-upper-links p:hover {
  color: #FFE300;
}

.header-upper .nav-link {
  color: #444444;
  font-weight: 600;
  font-size: 19px !important;

}

.header-upper .nav-link:hover {
    background: -webkit-linear-gradient(90deg,  #FFE300 , #1b1a17);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.pattern-text {
    background: -webkit-linear-gradient(90deg,  #FFE300 , #1b1a17);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.pattern-text-2 {
    background: -webkit-linear-gradient(90deg,  #FFE300 , #f9f9f9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* .header-upper {
  background: radial-gradient(circle at 10% 20%, rgb(255, 131, 61) 0%, rgb(250, 145, 9) 90%);
} */
.header-upper .menu-links a,
.header-upper .dropdown button {
  color: white;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0.3;
  text-transform: uppercase;
}
.header-upper .dropdown button:focus {
  box-shadow: none;
}

.header-upper .dropdown-menu {
  background-color: #ffffff;
  width: 100%;
  transform: translate3d(0px, 57px, 0px) !important;
}

.header-upper .dropdown-item {
  padding: 20px;
  margin-bottom: 2px;
  border-bottom: 1px solid var(--color-3b4149);
}

.header-upper .dropdown-item:focus {
  background-color: transparent;
  color: var(--color-febd69) !important;
}

.info-line {
  background: #444444 !important;
}

/*Header End */
/*////////////////////////////////////////////////////////////////*/
/*Footer Start */
.footer {
  background-color: #444444;
}

.footer:not(:first-child) {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .input-group-text {
  background-color: var(--color-232f3e);
  color: white;
  padding: 8px 17px;
}
#apple,
#twitter,
#github,
#facebook {
  font-size: 1.5em;
  background-color: #18191f;
  color: #fff;
  box-shadow: 2px 2px 2px #00000080, 10px 1px 12px #00000080,
    2px 2px 8px #00000080, 2px 2px 3px #00000080, inset 2px 2px 8px #00000080,
    inset 2px 2px 8px #00000080, inset 2px 2px 8px #00000080,
    inset 2px 2px 8px #00000080;
  border-radius: 10px;
  margin-top: 10px;
  animation: animate 3s linear infinite;
  text-shadow: 0 0 20px #0072ff, 0 0 40px #0072ff, 0 0 60px #0072ff,
    0 0 80px #0072ff;
}

#twitter {
  animation-delay: 0.3s;
}

#facebook {
  animation-delay: 0.7s;
}

#github {
  animation-delay: 0.1s;
}

@keyframes animate {
  from {
    filter: hue-rotate(0deg);
  }

  to {
    filter: hue-rotate(360deg);
  }
}
/*Footer End */
/*////////////////////////////////////////////////////////////////*/
/*Home Page Start*/
/*Home wrapper 1*/
.main-banner-content {
  top: 15%;
  left: 5%;
  
}
.main-banner img {
  width: 100%;
}
.main-banner-content h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-bf4800);
  margin: 0 0 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}
.main-banner-content h5 {
  font-size: 42px;
  line-height: 54px;
  letter-spacing: -2px;
  font-weight: 500;
  text-transform: none;
  
}
.main-banner-content p {
  padding-top: 45%;
  font-size: 28px;
  line-height: 28px;
  letter-spacing: 0.4px;

  text-transform: uppercase;
}
.small-banner-content {
  top: 25%;
  left: 10%;
}
.small-banner-content h4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  /* color: var(--color-3b4149); */
  margin: 0 0 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color:var(--color-131921)
}
.small-banner-content h5 {
  font-size: 22px;
  line-height: 34px;
  letter-spacing: -2px;
  font-weight: 500;
  text-transform: none;
  color :#232f3e;
}
.small-banner-content p {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.4px;

  text-transform: uppercase;
}
.small-banner {
  width: 49%;
}
.home-wrapper-1 {
  background-color: var(--color-f5f5f7);

}
/*Home wrapper 1 end*/
/*////////////////////////////////////////////////////////////////*/

/*Home wrapper 2*/
.home-wrapper-2 {
  background-color: var(--color-f5f5f7);
}
/*Home wrapper 2 end*/
/*////////////////////////////////////////////////////////////////*/

/*Category wrapper 2*/
.categories {
  background-color: var(--color-f5f5f7);
  box-shadow: 0 0 10px #0000001a;
  padding: 15px;
}

.categories > div {
  width: 25%;
  padding: 10px 10px 10px 20px;
}
.categories > div > div {
  width: 60%;
}
.categories > div:nth-child(1),
.categories > div:nth-child(2),
.categories > div:nth-child(3),
.categories > div:nth-child(4) {
  border-bottom: 1px solid var(--color-ededed);
}

.categories > div:nth-child(1),
.categories > div:nth-child(2),
.categories > div:nth-child(3) {
  border-right: 1px solid var(--color-ededed);
}
.categories > div:nth-child(5),
.categories > div:nth-child(6),
.categories > div:nth-child(7) {
  border-right: 1px solid var(--color-ededed);
}

/*Category wrapper 2 end*/
/*////////////////////////////////////////////////////////////////*/
/*Blog Part*/
.blog-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  overflow: hidden; /* Chắc chắn ảnh sẽ không tràn khỏi khung của div */
  height: 90%;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: transform 0.5s ease; 
}


.blog-card .img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}


.blog-card .card-image {
  width: 100%;
  height: 200px; /* Đảm bảo chiều cao của ảnh là 200px */
  overflow: hidden; /* Ẩn phần ảnh vượt qua chiều cao của div */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.blog-card .card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Hiển thị ảnh mà không làm thay đổi tỷ lệ khung hình */
}

.blog-content {
  padding: 15px;
  height: 200px; /* Đảm bảo chiều cao tối đa cho nội dung */
  overflow: hidden; /* Ẩn phần nội dung vượt qua chiều cao của div */
}

.blog-content .date {
  font-size: 13px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--color-777777);
  padding: 0;
}

.blog-content h5 {
  font-size: 18px;
  color: var(--color-131921);
  overflow: hidden;
  text-overflow: ellipsis; /* Ẩn phần vượt qua chiều dài và thêm dấu "..." nếu cần */
  white-space: nowrap; /* Đảm bảo tiêu đề không xuống dòng */
}

.blog-content p.desc {
  font-size: 13px;
  line-height: 22px;
  color: var(--color-777777);
  overflow: hidden;
  text-overflow: ellipsis; /* Ẩn phần vượt qua chiều dài và thêm dấu "..." nếu cần */
  white-space: nowrap; /* Đảm bảo tiêu đề không xuống dòng */ 
}

.button.read-more {
  position: absolute;
  bottom: 40px;
  left: 5;
}

.single-blog-card a {
  font-size: 15px;
  color: var(--color-777777);
  margin-bottom: 50px;
}

.single-blog-card h3 {
  font-size: 24px;
  font-weight: 600;
  color: var(--color-1c1c1b);
}

.single-blog-card p {
  color: var(--color-777777);
  font-size: 14px;
}

/*Blog Part End*/
/*////////////////////////////////////////////////////////////////*/

/*Product Part*/
.product-card {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: transform 0.5s ease; 
}

.product-card:hover {
  transform: translateY(-5px); /* Move the div up by 5 pixels when hovered */
}

.product-card p.description {
  font-size: 13px;
  color: black;
  margin-right: 12px;
}

.product-card .product-image {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}

.product-card .product-image img {
  width: 100%;
  height: 340px; /* Đảm bảo chiều cao cố định cho ảnh */
  object-fit: cover;
}

.product-card .product-image > img:last-child {
  display: none;
}

.product-card:hover .product-image > img:nth-child(1) {
  display: none;
}
.product-card:hover .product-image > img:last-child {
  display: block;
}

.product-card:hover .action-bar {
  right: 15px;
}

.product-card-small {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: transform 0.5s ease; 
}

.product-card-small p.description {
  font-size: 13px;
  color: black;
  margin-right: 12px;
}

.product-card-small .product-image {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}

.product-card-small .product-image img {
  width: 100%;
  height: 230px; /* Đảm bảo chiều cao cố định cho ảnh */
  object-fit: cover;
}

.product-card-small .product-image > img:last-child {
  display: none;
}

.product-card-small:hover .product-image > img:nth-child(1) {
  display: none;
}
.product-card-small:hover .product-image > img:last-child {
  display: block;
}

.product-card-small:hover .action-bar {
  right: -15px;
}

.product-card-small .product-details {
  padding: 15px;
  height: 100px; /* Đảm bảo chiều cao cố định cho nội dung */
  overflow: hidden;
}

.product-card-small .product-details h6 {
  font-size: 13px;
  background: -webkit-linear-gradient(90deg,  var(--color-bf4800) , #1b1a17);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.product-card-small .product-details h5 {
  font-size: 15px;
  color: var(--color-131921);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-card .product-details {
  padding: 15px;
  height: 100px; /* Đảm bảo chiều cao cố định cho nội dung */
  overflow: hidden;
}

.product-card .product-details h6 {
  
  font-size: 13px;
  background: -webkit-linear-gradient(90deg , #776632, #f67925);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 13px;
}
.product-card .product-details h5 {
  font-size: 15px;
  color: var(--color-131921);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-card .product-details p.price {
  font-size: 16px;
  color: var(--color-1c1c1b);
}
.action-bar {
  top: 10%;
  right: -23px;
  transition: 0.5s;
}
.wishlist-icon {
  top: 2%;
  right: 15px;
}
/*Product Part End  */
/*////////////////////////////////////////////////////////////////*/

/*Special Product Part */
.special-product-card {
  padding: 26px;
  overflow: hidden;
  width: 100%;
  height: 90%;
  background-color: white;
  border-radius: 10px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: transform 0.5s ease; 
}
.special-product-card .special-img{
  display: flex;
  padding-right: 10%;
  padding-bottom: 10%;
  width: 80%;
  height: 60px; /* Đảm bảo chiều cao của ảnh là 200px */
  overflow: hidden; /* Ẩn phần ảnh vượt qua chiều cao của div */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
/*Special Product Part End  */
/*Home Page End*/

/*////////////////////////////////////////////////////////////////*/
/*Our Store Page */
.filter-card {
  background-color: white;
  border-radius: 10px;
  padding: 10px 15px;
}

.filter-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 20px;
}

.filter-card .form-check-input:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.25);
}

.filter-card .form-check-input:checked {
  background-color: var(--color-febd69);
  border-color: var(--color-febd69);
}

.filter-card ul,
.filter-card .form-check-label {
  list-style-type: none;
  font-size: 13px;
  line-height: 30px;
  color: var(--color-777777);
  cursor: pointer;
}

.sub-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 15px;
}

.filter-sort-grid {
  padding: 10px;
  background-color: white;
  border-radius: 10px;
}

.grid img {
  width: 35px;
  height: 35px;
  background-color: #f2f2f2;
  padding: 8px;
  border-radius: 3px;
  cursor: pointer;
}

.product-list {
  padding-top: 20px;
}

.gr-12 {
  width: 100%;
}
.gr-6 {
  width: 49%;
}
.gr-4 {
  width: 32%;
}
.gr-3 {
  width: 24%;
}

.gr-12 .product-card {
  display: flex;
  gap: 20px;
}

.gr-6 .product-image {
  width: 100%;
}

.gr-6 .product-image img {
  margin: auto;
  display: block;
}

.gr-12 .product-card .product-image {
  width: 270px;
  height: auto;
}
.gr-12 .action-bar {
  top: 17%;
}
/*Our Store Page End */

/*Contact Page*/
.contact-inner-wrapper {
  padding: 30px 20px;
  border-radius: 15px;
  background-color: white;
  gap: 15px;
}
.contact-inner-wrapper > div {
  width: 40%;
}
.contact-title {
  font-size: 26px;
  font-weight: 500;
  line-height: 35px;
  text-align: left;
}

.contact-inner-wrapper form .form-control {
  background-color: var(--color-f5f5f7);
  border-radius: 10px;
  border-color: transparent;
  padding: 10px;
}

.contact-inner-wrapper ul {
  list-style: none;
}

.contact-inner-wrapper address,
.contact-inner-wrapper a,
.contact-inner-wrapper p {
  color: var(--color-777777);
}
/*Contact Page End */



/*Compare Product */
.wishlist-card {
  padding: 20px 15px;
  background-color: white;
  border-radius: 20px;
  border: 1px solid rgb(0, 0, 0, 0.3);
}
.wishlist-card .cross {
  top: 15px;
  right: 15px;
  width: 25px;
  padding: 3px;
  cursor: pointer;
}

.wishlist-details {
  padding: 15px;
  height: 220px; /* Đảm bảo chiều cao cố định cho nội dung */
  overflow: hidden;
}
.wishlist-details h5.title {
  font-size: 24px;
  line-height: 22px;
  font-weight: 400;
  
}

.wishlist-details p {
  margin-top: 5px;
  color: red;
  text-transform: uppercase;
}
.availability {
  display: flex;
  align-items: center;
}

.availability h5 {
  margin-right: 10px;
}

.wishlist-product-card .product-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
}
/*Compare Product End */

/*Login Page*/
.auth-card {
  padding: 20px;
  background-color: white;
  width: 500px;
  margin: 30px auto;
  border-radius: 10px;
}
.auth-card h3 {
  font-size: 19px;
  font-weight: 500;
  color: var(--color-777777);
}
.auth-card p {
  font-size: 13px;
  color: var(--color-777777);
}

.auth-card .form-control {
  height: 50px;
  background-color: var(--color-f5f5f7);
  color: var(--color-777777);
  font-size: 14px;
  border-radius: 10px;
  border-color: transparent;
}

.auth-card a {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-1c1c1b);
}

.auth-card .signup {
  background-color: var(--color-febd69);
  color: var(--color-131921);
}

.auth-card .signup:hover {
  background-color: var(--color-131921);
  color: white;
}

/*Login Page End*/

/*Single Product */
.description-wrapper h4,
.review-wrapper h4 {
  font-size: 26px;
  color: #1c1c1b;
  margin-bottom: 10px;
}
.description-wrapper p {
  font-size: 14px;
  color: var(--color-777777);
}

.review-inner-wrapper {
  background-color: white;
  padding: 30px;
}
.review-head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}

.review-head h4 {
  font-size: 18px;
  color: var(--color-1c1c1b);
}
.review-head p,
.review-head a {
  font-size: 14px;
  color: var(--color-777777);
}
.review-form h4 {
  font-size: 16px;
  color: var(--color-777777);
}
.review-form {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.review:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.review p {
  font-size: 14px;
  color: var(--color-777777);
}

.main-product-image {
  padding: 40px 70px; 
  background-color: white;
  border-radius: 10px;

  /* width: 500px;
  height: 400px; */
}

.main-product-image > div {
  border: 1px solid rgba(0, 0, 0, 0.1);

}
.main-product-image-main {
  text-align: center;

  /* width: 500px !important;
  height: 500px !important; */
  object-fit: cover;
}

.other-product-images {
  background-color: white;
  padding: 10px;
  display: flex;
  overflow-x: auto;
}

.other-product-images img {
  flex: 0 0 auto;
  width: 100px;
  margin-right: 10px;
}


.main-product-image .js-image-zoom__zoomed-image {
  left: 40px !important;
  padding: 10px;
  background-color: black;
  border-radius: 10px;
}

.main-product-details {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}
.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.main-product-details h3.title {
  font-size: 30px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 10px;
}

.main-product-details p.price {
  font-size: 16x;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.main-product-details a.review-btn,
.t-review {
  color: var(--color-777777);
  font-size: 13px;
}
p.mb-0.t-review {
  padding-top: 8px;
}

.product-heading {
  font-size: 14px;
  color: var(--color-1c1c1b);
  margin-bottom: 0px;
}

.product-data {
  font-size: 13px;
  columns: var(--color-777777);
  margin-bottom: 0px;
}

.main-product-details a {
  font-size: 14px;
  color: var(--color-777777);
}
/*Single Product End*/

/* Cart Start */
.cart-header,
.cart-data {
  border-bottom: 1px solid #eaeaea;
}

.cart-header h4 {
  font-size: 13px;
  color: var(--color-777777);
}

.cart-col-1 {
  width: 40%;
}

.cart-col-1 p {
  color: var(--color-777777);
  font-size: 14px;
}

.cart-col-2 {
  width: 10%;
}
.cart-col-3 {
  width: 15%;
}
.cart-col-4 {
  width: 15%;
}

.cart-data h5.price {
  font-size: 14px;
  color: var(--color-1c1c1b);
}

/* Cart End */

/* Shipping page start */
.total {
  font-size: 14px;
  color: var(--color-1c1c1b);
}
.total-price {
  font-size: 16px;
  color: var(--color-777777);
}

.table-order-detail {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-top: 24px;
  padding: 0 20px;
}
.table-order-detail table thead tr th {
  border: 0;
  border-bottom: 1px solid #e0e0e0;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  padding: 25px 0;
}

/* Shipping page end */

.quill{
    background-color: white;
}

.form-control{
    box-shadow: none;
    border-color: var(--color-c3d4da);
}
.title{
    font: 28px;
    /* font-family: Roboto !important; */
    color: var(--color-212529)
}
.sub-title{
    font: 24px;
    font-family: Roboto !important;
    color: var(--color-212529)
}
.desc{
    font:14px;
    font-family: Roboto !important;
    color: var(--color-828599)
}
/*Confirm order*/

.btn-section {
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #FFF;
    font-size: 18px;
    float: left;
    width: 23%;
    margin: 1%;
    padding: 0px 20px;
    
    .btn-gradient {  
      position: relative;
      left: -20px;
      width: 40%;
      background: rgba(0, 0, 0, 0.15);
      box-shadow: 2px 0px 0px 0px rgba(78, 72, 72, 0.4);
      border-top-right-radius: 60px;
      padding: 10px;
      
      i {
        font-size: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
    &.btn-green {
      background-color: #47a447; 
    }

    &.btn-pink {
      background-color: #f11350;
    }

    &.btn-facebook {
      background-color: #5863db;
    }

    &.btn-instagram {
      background-color: #cb31d7;
    }

    &.btn-twitter {
      background-color: #58cadb;
    }

    &.btn-google {
      background-color: #f06262;
    }

    &.btn-github {
      background-color: #5a5a5a;
    }

    &.btn-behance {
      background-color: #0069ff;
    }

    &.btn-dribbble {
      background-color: #f0709f;
    }

    &.btn-linkedin {
      background-color: #0074af;
    }

    &.btn-pinterest {
      background-color: #c41f26;
    }

    &.btn-snapchat {
      background-color: #fffc01;
    }
  }
  
  .btn-text {
    width: 60%;
  }
  
  &.btn-radius {
    .btn,
    .btn-gradient {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
    }
  }
}
